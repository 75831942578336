<template>
  <main class="flex bg-transparent xl:px-6 lg:px-6 md:px-8 px-3">
    <NotificationToast
      v-if="notification"
      :notification="notification"
      @resetNotification="notification = null"
    />
    <LoadingSpinner v-if="!product" />
    <section v-if="product" class="w-full">
      <main>
        <section
          class="
            capitalize
            flex
            items-center
            mb-24
            xl:text-base
            lg:text-base
            md:text-sm
            text-xs
          "
        >
          <home-icon
            size="1.3x"
            class="custom-class text-primary-color cursor-pointer"
            @click="$router.push({ name: 'Purchases' })"
          ></home-icon
          ><span class="mx-2">/</span>
          <span class="capitalize text-primary-color cursor-pointer">
            {{
              categoryId ? $route.params.purchaseCategory : 'All stores'
            }}</span
          >
          <span class="mx-2">/</span>
          <span
            class="capitalize text-primary-color cursor-pointer"
            @click="
              $router.push({
                name: 'PurchaseCategoryMerchant',
                params: {
                  purchaseCategory: $route.params.purchaseCategory,
                  merchant: $route.params.merchant,
                },
              })
            "
          >
            {{ $route.params.merchant }}</span
          >
          <span class="mx-2">/</span>
          <span class="capitalize"> {{ $route.params.product }}</span>
        </section>
        <section class="flex xl:flex-row lg:flex-row md:flex-col flex-col">
          <div
            class="
              flex
              xl:flex-row
              lg:flex-row
              md:flex-col
              flex-col
              items-start
              xl:w-1/3
              lg:w-1/3
              md:w-full
              max-w-md
              w-full
            "
          >
            <div class="w-full h-full xl:mr-3 lg:mr-3 md:mr-0 mr-0">
              <img
                v-if="productImages.length"
                :src="`${url}/${productImages[0].path}`"
                :alt="product.name"
                class="w-full h-full object-contain"
              />
            </div>
            <div
              v-if="productImages.length > 1"
              class="xl:w-1/5 lg:w-1/5 md:w-full w-full flex-shrink-0"
            >
              <div
                class="
                  grid
                  xl:grid-cols-1
                  lg:grid-cols-1
                  md:grid-cols-4
                  grid-cols-4
                  gap-3
                  xl:mt-0
                  lg:mt-0
                  md:mt-3
                  mt-3
                "
              >
                <div v-for="(image, index) in productImages" :key="index">
                  <img
                    v-if="index > 0"
                    :src="`${url}/${image.path}`"
                    :alt="product.name"
                    class="w-full h-full object-contain"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            class="
              xl:w-96
              lg:w-96
              md:w-full
              w-full
              xl:ml-6
              lg:ml-6
              md:ml-0
              ml-0
              xl:mt-0
              lg:mt-0
              md:mt-8
              mt-8
              text-legend-gray
              dark:text-border-color
              flex flex-col
              justify-between
            "
          >
            <h1 class="text-2xl font-semibold mb-2">{{ product.name }}</h1>
            <div class="mt-4 mb-12">
              {{ product.description }}
            </div>
            <div class="text-3xl font-bold">
              <div>₦{{ product.price | moneyFormat }}</div>
              <!-- <div class="my-3 text-gray-400 flex items-center">
                <div>
                  <strike> ₦{{ product.price | moneyFormat }} </strike>
                </div>
                <div
                  class="
                    text-primary-color
                    ml-6
                    px-3
                    py-1
                    rounded-full
                    bg-primary-color bg-opacity-20
                    text-base
                  "
                >
                  -10%
                </div>
              </div> -->
            </div>
            <div class="flex items-center my-7 dark:text-primary-color">
              <span
                class="
                  bg-main-border
                  p-1
                  rounded-lg
                  w-8
                  h-8
                  text-center
                  flex
                  items-center
                  justify-center
                  cursor-pointer
                  select-none
                "
                @click="decrementQuantity"
                >-</span
              >
              <span
                class="
                  border border-main-border
                  p-1
                  rounded-lg
                  w-8
                  h-8
                  text-center
                  flex
                  items-center
                  justify-center
                  font-bold
                  mx-2
                "
                >{{ quantity }}</span
              >
              <span
                class="
                  bg-main-border
                  p-1
                  rounded-lg
                  w-8
                  h-8
                  text-center
                  flex
                  items-center
                  justify-center
                  cursor-pointer
                  select-none
                "
                @click="incrementQuantity"
                >+</span
              >
            </div>
            <div class="flex text-lg my-5">
              <div
                class="
                  w-44
                  capitalize
                  text-primary-color
                  border border-primary-color
                  rounded-lg
                  py-3
                  mr-5
                  flex
                  items-center
                  justify-center
                  cursor-pointer
                "
                @click="addToCart"
              >
                <div class="flex items-center">
                  <img
                    src="@/assets/images/cart-colored.svg"
                    alt="add to cart"
                  />
                  <span class="ml-2">Add to Cart</span>
                </div>
              </div>
              <router-link
                :to="{
                  name: 'Checkout',
                  query: {
                    product: product.name,
                    description: product.description,
                    price: product.price,
                    quantity,
                    id: product.id,
                  },
                }"
                class="
                  w-44
                  capitalize
                  bg-primary-color
                  border border-primary-color
                  text-white
                  rounded-lg
                  py-3
                  text-center
                "
              >
                buy now
              </router-link>
            </div>
          </div>
        </section>
        <section class="xl:w-3/4 lg:w-3/4 md:w-full w-full mt-24 mb-12">
          <nav
            class="
              xl:text-lg
              lg:text-lg
              md:text-base
              text-base
              border-b border-border-color
            "
          >
            <ul class="flex justify-evenly">
              <li
                class="py-3 cursor-pointer"
                :class="{
                  'text-primary-color font-semibold': isProductDetails,
                }"
                @click="toggleProductDetails"
              >
                Product Details
              </li>
              <li
                class="py-3 cursor-pointer"
                :class="{
                  'text-primary-color font-semibold': isPackagingInformation,
                }"
                @click="togglePackagingInformation"
              >
                Packaging Information
              </li>
              <li
                class="py-3 cursor-pointer"
                :class="{
                  'text-primary-color font-semibold': isDeliveryInformation,
                }"
                @click="toggleDeliveryInformation"
              >
                Delivery Information
              </li>
            </ul>
          </nav>
          <main
            v-html="productInformation"
            class="
              xl:w-1/2
              lg:w-1/2
              md:w-full
              w-full
              text-sm
              leading-relaxed
              text-legend-gray
              dark:text-border-color
              pt-4
              pb-20
            "
          ></main>
        </section>
      </main>
    </section>
    <custom-modal v-if="addingToCart && cart.length">
      <CartSystem slot="cart" :cart="cart" @close-cart="addingToCart = false" />
    </custom-modal>
  </main>
</template>

<script>
import CartSystem from '@/components/Cart.vue'
import { HomeIcon } from 'vue-feather-icons'
import { mapGetters } from 'vuex'
import errorModule from '@/util/error.handle'

export default {
  name: 'productInformation',
  components: { CartSystem, HomeIcon },
  async mounted() {
    await this.fetchProduct()
    if (this.isProductDetails) {
      this.productInformation = this.product.description
    }
  },
  watch: {
    isDeliveryInformation() {
      if (this.isDeliveryInformation) {
        this.productInformation = this.product.delivery_details
      }
    },
    isPackagingInformation() {
      if (this.isPackagingInformation) {
        this.productInformation = this.product.packaging_details
      }
    },
    isProductDetails() {
      if (this.isProductDetails) {
        this.productInformation = this.product.description
      }
    },
  },
  data() {
    return {
      product: null,
      productInformation: null,
      productImages: [],
      quantity: 1,
      isProductDetails: true,
      isPackagingInformation: false,
      isDeliveryInformation: false,
      addingToCart: false,
      notification: null,
      url: process.env.VUE_APP_API_URL_HOST,
    }
  },
  computed: {
    ...mapGetters(['cart', 'categoryId']),
  },
  methods: {
    toggleProductDetails() {
      this.isProductDetails = true
      this.isPackagingInformation = false
      this.isDeliveryInformation = false
      this.isProductRating = false
    },
    togglePackagingInformation() {
      this.isProductDetails = false
      this.isPackagingInformation = true
      this.isDeliveryInformation = false
      this.isProductRating = false
    },
    toggleDeliveryInformation() {
      this.isProductDetails = false
      this.isPackagingInformation = false
      this.isDeliveryInformation = true
      this.isProductRating = false
    },
    toggleProductRating() {
      this.isProductDetails = false
      this.isPackagingInformation = false
      this.isDeliveryInformation = false
      this.isProductRating = true
    },
    decrementQuantity() {
      if (this.quantity > 1) {
        this.quantity--
      }
    },
    incrementQuantity() {
      this.quantity++
    },
    async addToCart() {
      try {
        const { status } = await this.axios.post('/account/cart', {
          product_id: this.product.id,
          quantity: this.quantity,
        })

        if (status >= 200 && status < 300) {
          this.fetchCartItems()
          this.addingToCart = true
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
      }
    },
    async fetchProduct() {
      try {
        const { status, data } = await this.axios.get(
          `account/products/${this.$route.query.prd___qnty}`
        )

        if (status === 200) {
          this.product = data.data
          this.product.images.forEach((image) => {
            if (this.product.images.length) {
              this.productImages.push(image)
            }
          })
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
      }
    },
    async fetchCartItems() {
      try {
        const { status, data } = await this.axios.get('/account/cart')

        if (status === 200) {
          this.$store.commit('SET_CART', data.data.cart_items)
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
      }
    },
  },
}
</script>

<style scoped>
.products {
  width: 92px;
}
</style>
